// src/api/index.js
import axios from "axios";

// Create an Axios instance
const instance = axios.create({
  baseURL: "http://localhost:8000", // Base URL for your API
});

// Add a request interceptor to include the token in the headers
instance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage (or any storage you're using)
    const token = localStorage.getItem("token");

    // If a token exists, add it to the request headers
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Add the token to the Authorization header
    }

    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

export default instance;
