// import React, { useEffect, useState } from "react";
// import { Route, Routes } from "react-router";
// import Home from "./pages/Home";
// import ShopByCategory from "./pages/ShopByCategory";
// import Cart from "./pages/Cart";
// import { getToken } from "./api/storage";
// import UserContext from "./context/userContext";
// import Login from "./pages/Login";
// import Register from "./pages/Register";
// import ShippingForm from "./pages/ShippingForm";
// import PaymentForm from "./pages/PaymentForm ";
// import OrderReview from "./pages/OrderReview";
// import OrderConfirmation from "./pages/OrderConfirmation";
// import CheckoutPage from "./pages/CheckoutPage";
// import MyOrders from "./pages/MyOrders";
// import ContactUs from "./pages/ContactUs";

// function App() {
//   const [user, setUser] = useState(false);

//   useEffect(() => {
//     if (getToken()) setUser(true);
//   }, []);

//   return (
//     <UserContext.Provider value={{ user, setUser }}>
//       <Routes>
//         <Route path="/" Component={Home} />
//         <Route path="/Login" Component={Login} />
//         <Route path="/Register" Component={Register} />

//         <Route path="/Home" Component={Home} />
//         <Route path="/MyOrders" Component={MyOrders} />
//         <Route path="/ShopByCategory" Component={ShopByCategory} />
//         <Route path="/ContactUs" Component={ContactUs} />
//         <Route path="/Cart" Component={Cart} />
//         <Route path="/checkout" Component={CheckoutPage} />
//         <Route path="/shipping" Component={ShippingForm} />
//         <Route path="/payment" Component={PaymentForm} />
//         <Route path="/review" Component={OrderReview}/>
//         <Route path="/confirmation" Component={OrderConfirmation} />
//       </Routes>
//     </UserContext.Provider>
//   );
// }

// export default App;
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ShopByCategory from "./pages/ShopByCategory";
import Cart from "./pages/Cart";
import { getToken } from "./api/storage";
import UserContext from "./context/userContext";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ShippingForm from "./pages/ShippingForm";
import OrderReview from "./pages/OrderReview";
import OrderConfirmation from "./pages/OrderConfirmation";
import CheckoutPage from "./pages/CheckoutPage";
import MyOrders from "./pages/MyOrders";
import ContactUs from "./pages/ContactUs";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";  // Import react-query

const queryClient = new QueryClient();  // Initialize QueryClient

function App() {
  const [user, setUser] = useState(false);

  useEffect(() => {
    if (getToken()) setUser(true);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>  {/* Wrap your app with QueryClientProvider */}
      <UserContext.Provider value={{ user, setUser }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/MyOrders" element={<MyOrders />} />
          <Route path="/ShopByCategory" element={<ShopByCategory />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/shipping" element={<ShippingForm />} />
          <Route path="/review" element={<OrderReview />} />
          <Route path="/confirmation" element={<OrderConfirmation />} />
        </Routes>
      </UserContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
